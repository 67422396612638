import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IgxSnackbarComponent } from 'igniteui-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { InfogeonClassroomService } from '../infogeon-classroom.service'
import * as alertify from 'alertifyjs';
import { s3_file_path } from 'src/app/config/api';
import { Utils } from 'src/app/config/utils';
import { GlobalConstants } from 'src/app/global-const';
import { ExcelServicesService } from 'src/app/services/excel-services.service';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { InfogeonLearningChampionService } from '../../infogeon-learning-champion/infogeon-learning-champion.service';

@Component({
  selector: 'app-individual-session',
  templateUrl: './individual-session.component.html',
  styleUrls: ['./individual-session.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndividualSessionComponent implements OnInit {
  @ViewChild(IgxSnackbarComponent)
  public snackbar: IgxSnackbarComponent;

  modalRef: NgbModalRef = null;

  userDropdownSettings = {
    singleSelection: false,
    text: "Select users",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    showCheckbox: true,
    labelKey: 'full_name',
    maxHeight: 120,
    // lazyLoading: true,
    primaryKey: 'uid',
    searchBy: ["uid", "full_name"],
    classes: "select_user_drop primaryText"
  };
  attributeDropdownSettings = {
    singleSelection: true,
    text: "Select Attribute",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    showCheckbox: true,
    labelKey: 'attribute_name',
    maxHeight: 120,
    // lazyLoading: true,
    primaryKey: 'aid',
    searchBy: ["aid", "attribute_name"],
    classes: "select_user_drop primaryText"
  };
  attributeOptionDropdownSettings = {
    singleSelection: true,
    text: "Select Attribute Option",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    showCheckbox: true,
    labelKey: 'attribute_option',
    maxHeight: 120,
    // lazyLoading: true,
    primaryKey: 'aoid',
    searchBy: ["aoid", "attribute_option"],
    classes: "select_user_drop primaryText"
  };


  fetchUserList: any[] = [];
  dataList: any[] = [];
  userList: any[] = [];
  selectedUsers: any[] = [];
  screenWidth: number;
  userType: any;
  sessionIdEntered: any;
  isMobile: boolean;
  attendanceSubmitted: boolean = false;
  sid: any;
  session_name: any;
  classroom_type: any;
  group_name: any;
  meeting_link: any;
  address: any;
  geolocation: any;
  time_data: any;
  module_name: any;
  module_desc: any;
  session_key: any;
  trainer_name: any;
  sub_skill: any;
  skill: any;
  user_status: any;
  assigned_count: any;
  enrolled_count: any;
  attended_count: any;
  attendanceSelected: any;
  attendance_status: any;
  sess_status: any;
  file_path: any;
  module_image: any;
  session_got_canceled: any;
  attribute : any;
  session_type : any;
  selectedAttributeOption : any;
  attributeOptions : any;
  backUrl: any = '/classroom/user_session_list';
  selectedAttribute:any;
  isMarkedbutton:boolean = false;
  is_lc_session: any;
  session_users: any;
  user_pre_score: any;
  user_post_score: any;
  scoreSec: boolean = false;
  constructor(
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private classroomService: InfogeonClassroomService,
    private excelService: ExcelServicesService,
    private titleService: Title,
    private datePipe: DatePipe,
    private router: Router,
    private infogeonLcService: InfogeonLearningChampionService,
    private cdr: ChangeDetectorRef,
  ) {
    this.titleService.setTitle("Classroom");
  }

  ngOnInit(): void {

    this.all_users_list()
    
    this.activatedRoute.params.subscribe(paramsId => {
      var that = this;
      this.sid = paramsId.sid;

      const data = {
        "sid": this.sid
      };

      let getPromise = new Promise((resolve, reject) => {
        this.classroomService.fetch_session_by_id(data).toPromise().then(res => {
          this.dataList = res;
          // console.log(this.dataList)
          this.dataList['fetchUserList'].forEach(function (item: any){
            item.checked = false
            // console.log(item)
          })

          // console.log(this.dataList['fetchUserList'])
          this.session_got_canceled = this.dataList['session_got_canceled']
          this.userType = this.dataList['userType']
          this.session_name = this.dataList['session_name']
          this.classroom_type = this.dataList['classroom_type']
          this.group_name = this.dataList['group_name']
          this.meeting_link = this.dataList['meeting_link']
          this.address = this.dataList['address']
          this.geolocation = this.dataList['geolocation']
          this.time_data = this.dataList['time_data']
          this.module_name = this.dataList['module_name']
          this.module_desc = this.dataList['module_desc']
          this.session_key = this.dataList['session_key']
          this.trainer_name = this.dataList['trainer_name']
          this.sub_skill = this.dataList['sub_skill']
          this.skill = this.dataList['skill']
          this.user_status = this.dataList['user_status']
          this.assigned_count = this.dataList['assigned_count']
          this.enrolled_count = this.dataList['enrolled_count']
          this.attended_count = this.dataList['attended_count']
          this.fetchUserList = this.dataList['fetchUserList'].slice()
          this.selectedUsers = this.dataList['fetchUserList'].slice()
          this.attendance_status = (this.dataList['attendance_status'] == 1)?2:this.dataList['attendance_status']
          this.sess_status = this.dataList['status']
          this.module_image = this.dataList['module_image']
          this.attribute = this.dataList['attributes']
          this.session_type = this.dataList['session_type']
          this.is_lc_session = this.dataList['is_lc_session'];
          this.sid = this.dataList['sid'];
          // console.log(this.attribute)
          // console.log(this.fetchUserList)
          // console.log(this.session_type)
          if (this.module_image) {
            var file_url = ''
            var s3_file_key = `${s3_file_path}/${GlobalConstants.userDetails.cmid}/modules-images/${this.module_image}`
            file_url = Utils.aws_file_create(s3_file_key);
            this.file_path = file_url;
          } else {
            this.file_path = ''
          }

          if (this.dataList['attendanceSubmitted'] == 1) {
            this.attendanceSubmitted = true;
          } else {
            this.attendanceSubmitted = false;
          }

          if(this.userType == 'trainer'){
            if(this.is_lc_session == 1){
              this.backUrl = '/lc/lc_session_list';
            }else{
              this.backUrl = '/classroom/session_list';
            }         
          }

        })
      })

    });
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 576) { // for mobile
      this.isMobile = true
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 576) { // for mobile
      this.isMobile = true
    } else {
      this.isMobile = false
    }
  }

  /* To copy any Text */
  copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    // this.snackbar.open();
  }

  createUserModel(content) {
    // this.selectedAttribute = []
    // this.selectedAttributeOption = []
    
    this.selectedAttributeText = ""
    this.selectedAttribute = []
    this.selectedAttributeOption = []
    this.isAttributeMultiSelect = false
      this.isAttributeNoOption = false
    if(this.is_lc_session == 1){
      this.router.navigate(['/lc/lc_add_participant/', this.sid]);
    }else{
      // this.modalRef = this.modalService.open(content, {
      //   ariaLabelledBy: 'modal-basic-title'
      // });
      this.router.navigate(['/classroom/rtm_add_participant/', this.sid]);
    }
   
  }
  // createUnRegisteredModel(content){
    
  // }

  submitAttendance() {
    const data = {
      "session_key": this.sessionIdEntered,
      "sid": this.sid
    };

    let getPromise = new Promise((resolve, reject) => {
      this.classroomService.participant_attendance_update(data).toPromise().then(res => {
        //console.log(res)
        if (res == 'success') {
          alertify.success('Success');
          this.attendanceSubmitted = true;
        } else {
          alertify.error('Sorry, Please check the ID');
        }
        //this.individualsList = res;

      })
    })


    // this.attendanceSubmitted = true;
  }



  onSelectionChange(event, user, ): void {
    // Handle the selected value here
    event.stopPropagation();
    const is_attended = event.target.value
    const data = {
      "sid": this.sid,
      "is_attended": is_attended,
      "uid": user.uid,
    };
    // console.log("user update",this.fetchUserList);
    let getPromise = new Promise((resolve, reject) => {
      this.classroomService.admin_attendance_update(data).toPromise().then(res => {
        //console.log(res)
        if (res == 'success') {
          alertify.success('Success');
          if(is_attended == 0){
            user.pre_score = 0;
            user.post_score = 0;
          }
          this.cdr.detectChanges();
          //this.attendanceSubmitted = true;
        } else {
          alertify.error('Sorry, Something went wrong');
        }
      })
    })


  }



  attendance_status_update(att_stat) {
    this.attendance_status = att_stat
    // console.log(att_stat)
    if(att_stat == 1)
    {
      this.attendance_status = 2
    }
    const data = {
      "sid": this.sid,
      "attendance_status": att_stat,
    };

    let getPromise = new Promise((resolve, reject) => {
      this.classroomService.attendance_status_update(data).toPromise().then(res => {
        if (res == 'success') {
          alertify.success('Success');
          //this.attendanceSubmitted = true;
        } else {
          alertify.error('Sorry, Something went wrong');
        }
      })
    })
  }


  all_users_list() {
    this.classroomService.users_list().subscribe(users_list => {
      this.userList = users_list;
      // console.log(this.userList)
    })
  }


  add_new_users() {
    var that = this
    const data = {
      "user_list": this.selectedUsers,
      "sid": this.sid,
    };

    let getPromise = new Promise((resolve, reject) => {
      this.classroomService.add_user_individually(data).toPromise().then(res => {
        if (res == 'success') {
          alertify.success('Success');
          if (that.modalRef !== null) {
            that.modalRef.dismiss();
            that.modalRef = null; // Reset the modal reference
          }
          that.fetchParticipants()
          //this.attendanceSubmitted = true;
        } else {
          alertify.error('Sorry, Something went wrong');
        }
      })
    })

  }

  update_session_status(status, userList:any, session) {
    var that = this

    this.sess_status = status;

    const data = {
      "status": status,
      "sid": this.sid,
      "user_list": userList,
      "type": session,
      'session_type': this.is_lc_session
    };

    let getPromise = new Promise((resolve, reject) => {
      this.classroomService.update_session_status(data).toPromise().then(res => {
        if (res == 'success') {
          // alertify.success('Success');
          if(status == 3)
          {
            this.attendance_status_update(0)
          }
          else
          {
            this.attendance_status_update(1)
          }
          if (that.modalRef !== null) {
            that.modalRef.dismiss();
            that.modalRef = null; // Reset the modal reference
          }
        } else {
          alertify.error('Sorry, Please check the ID');
        }
      })
    })

  }

  fetchParticipants() {
    const data = {
      "sid": this.sid,
    };

    let getPromise = new Promise((resolve, reject) => {
      this.classroomService.participants_list(data).toPromise().then(res => {
        this.fetchUserList = res
        this.assigned_count = res.length
        // if(res.length>0)
        // {
        //   this.isMarkedbutton = true
        // }
        // else
        // {
          this.isMarkedbutton = false
        // }
      })
    })

  }


  openCloseModal(content) {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title'
    });
  }

  closeModal() {
    if (this.modalRef !== null) {
      this.modalRef.dismiss();
      this.modalRef = null; // Reset the modal reference
    }
  }


  enrollSession(event){
    event.stopPropagation();
    const data = {
      'sid': this.sid
    }
    this.classroomService.learner_enroll(data).toPromise().then(res => {
      this.user_status = 'enrolled'
      // this.spinner.hide();
    })

  }



  att_type:any;

  excelUser: any = [{
    first_name: '',
    last_name: '',
    mobile_no: '',
    emial_id: '',
    session_name: '',
    session_date: '',
    from_time: '',
    to_time: '',
    module_name: '',
    skill: '',
    sub_skill: '',
    session_type: '',
    // status: '',
    attendance: '',
    pre_score: '',
    post_score: '',
    user_type: '',
    trainer_name: '',
    session_status: '',
    trainer_type: '',
    duration: '',

  }]
  downloadUserData(){

  var that = this;
  this.classroomService.get_report_type_data('session',this.sid).toPromise().then(res => {
    // console.log(res)
    var trainer_type = ''
    var classroom_type = ''
    var session_status = ''
    // console.log(res.fetchUserList)
    that.excelUser = []
    if(res.trainer_type == 1){
      trainer_type = 'Facilitator';
    }else if(res.trainer_type == 2){
      trainer_type = 'Team Lead';
    }

    if (res.classroom_type == 0) {
      classroom_type = 'Online'
    } else if (res.classroom_type == 1) {
      classroom_type = 'Offline'
    } else {
      classroom_type = 'Online & Offline'
    }

    if(res.status == 0){
      session_status = 'Open';
    }else if(res.status == 1){
        session_status  = 'Completed';
    }else if(res.status == 2){
        session_status  = 'Cancelled';
    }else if(res.status == 3){
        session_status  = 'In progress';
    }else if(res.status == 4){
        session_status  = 'Expired';
    }

    var fetchUserList = res.fetchUserList
    fetchUserList.forEach(function (item: any) {
      var sess_type = ""
      // const date = new Date(res.time_data.session_date * 1000); // Multiply by 1000 to convert seconds to milliseconds
      // var session_date = that.datePipe.transform(date, 'yyyy-MM-dd') || '';
      // console.log(session_date)
      if (item.session_type == 0) {
        sess_type = 'Instant'
      } else {
        sess_type = 'Schedule'
      }
      if(item.is_attended == 0){
        that.att_type = 'Not Attended'
      }else{
        that.att_type='Attended'
      }
      var userType = ''
      if(item.registered == 1){
        userType= "Unregistered"
      }else{
        userType= "Registered"
      }
        
          that.excelUser.push({
            first_name: item.first_name,
            last_name: item.last_name,
            mobile_no: item.mobile_no,
            emial_id: item.email_id,
            session_name: res.session_name,
            type: classroom_type,
            session_date: res.time_data.session_date,
            from_time: res.time_data.from_time,
            to_time: res.time_data.to_time,
            duration: res.time_data.duration,
            module_name: res.module_name,
            category: res.skill,
            sub_category: res.sub_skill,
            session_type: sess_type,
            // status: item.user_status_display,
            attendance: that.att_type,
            pre_score: item.pre_score,
            post_score: item.post_score,
            user_type: userType,
            trainer_type: trainer_type,
            session_status: session_status,           
          });
    });
    // console.log('excel',this.excelUser)

    this.excelService.exportAsExcelFile(this.excelUser, 'user-session-list');
  })
  // this.fetchUserList.forEach(function (item: any){
  // //console.log(item)
  // if(item.is_attended == 0){
  //  that.att_type = 'Not Attended'
  // }else{
  //  that.att_type='Attended'
  // }


  //  that.excel.push({
  //         Name: item.full_name,
  //         Email_ID: item.email_id,
  //         Status: item.user_status_display,
  //         Attendance: that.att_type,
  //       });
  // });

  //});

  //console.log('excel',this.excel)

    // this.excelService.exportAsExcelFile(this.excel, 'user-session-list');


  }
  selectedAttributeText:any
  isAttributeMultiSelect=false;
  isAttributeSelect=false;
  isAttributeNoOption=false;
  onSelectedChange(value){
    // console.log(this.selectedAttribute)
    // console.log(this.selectedAttribute[0].attribute_option.length)
    if(this.selectedAttribute.length>0)
    {
      this.isAttributeSelect = true
    }
    if(this.selectedAttribute[0].attribute_option.length >0)
    {
      this.isAttributeMultiSelect = true
      this.isAttributeNoOption = false
    }
    else
    {
      this.isAttributeMultiSelect = false
      this.isAttributeNoOption = true
    }
    this.attributeOptions = value.attribute_option
  }
  isselectedAttribute = false
  attributeText=""
  attributeName=""
  get_users(){
    // console.log(this.selectedAttribute)
    // console.log(this.selectedAttribute[0].attribute_option)
    this.attributeName =this.selectedAttribute[0].attribute_name
    if(this.selectedAttribute == undefined)
    {
      this.isselectedAttribute = false
      alertify.error("Please select attribute")
    }
    else if(this.selectedAttributeOption == undefined && this.isAttributeMultiSelect)
    {
      this.isselectedAttribute = false
      alertify.error("Please select attribute option")
      this.selectedAttributeText = ""
    }
    else if(this.selectedAttributeText == undefined || this.selectedAttributeText == "" && this.isAttributeNoOption)
    {
      this.isselectedAttribute = false
      alertify.error("Please enter the text")
    }
    else
    {
      this.isselectedAttribute = true
      this.attributeText = (this.selectedAttributeText != "")?this.selectedAttributeText:this.selectedAttributeOption[0].attribute_option
      // console.log(this.selectedAttributeText)

      var that = this;
      let getPromise = new Promise((resolve, reject) => {
        var data = 
        {
          "selectedAttributeOption":(this.selectedAttributeOption==undefined)?[]:this.selectedAttributeOption,
          "selectedAttribute":this.selectedAttribute,
          "attributeText":(this.selectedAttributeText != "")?this.selectedAttributeText:""
        }
        this.classroomService.get_users_by_attribte(data).toPromise().then(res => {
          // console.log(res)
          this.selectedUsers = res
          if(res.length>0)
          {
            
          }
          else
          {
            alertify.error("No users are present")
          }
          this.add_new_users()
          if (that.modalRef !== null) {
            that.modalRef.dismiss();
            that.modalRef = null; // Reset the modal reference
          }
        

        })
      
      })
    }
  }
  handleCheckboxChange(event: any){
    var that = this;
    that.isMarkedbutton = false
    this.fetchUserList.forEach(function (item: any){
      if(item.checked)
      {
        // console.log(item);
        that.isMarkedbutton = true
        // this.count = 1
      }
    })
  }
  remove_marked_user(text)
  {
    var user_data = this.getMarkedData()
    // console.log(user_data);
    var data = 
    {
      "sid": this.sid,
      "user_data":user_data,
      "text":text,
    }
    // console.log("revoke",data)
      this.classroomService.remove_marked_user(data).toPromise().then(res => {
        this.fetchParticipants()
      })
    
    
  }
 
  getMarkedData()
  {
    var array = []
    this.fetchUserList.forEach(function (item: any){
      if(item.checked)
      {
        array.push(item)
        // this.count = 1
      }
    })
    return array;
  }

  downloadLcUserData(){
    var that = this;
    this.infogeonLcService.get_lc_individual_session_report(this.sid).toPromise().then(res => {
      // console.log(res);
      that.excelUser = []
      let session_data = res;
      // this.session_users = session_data.fetchUserList
      session_data.forEach(function (item: any,index) {
          var session_status = '';
          if(item.status == 0){
              session_status = 'Open';
          }else if(item.status == 1){
              session_status = 'Completed';
          }else if(item.status == 2){
              session_status = 'Cancelled';
          }else if(item.status == 3){
              session_status = 'In progress';
          }else if(item.status == 4){
              session_status = 'Expired';
          }


          var sess_type = ""
          if (item.session_type == 0) {
            sess_type = 'Instant'
          } else {
            sess_type = 'Schedule'
          }

          var userStatus=""
          if(item.is_attended == 1){
            userStatus = 'Completed'
          }else{
            userStatus ='Not Completed'
          }

          var userType = ''
          if(item.registered == 1){
            userType= "Unregistered"
          }else{
            userType= "Registered"
          }

          var data = {}
          data['first_name'] = item.first_name
          data['last_name'] = item.last_name
          data['mobile_no'] = item.mobile_no
          data['email_id'] = item.email_id
          data['module_name'] = item.module_name
          data['TranierName'] = item.trainer_name
          data['SessionName'] = item.session_name
          data['StoreCode'] = item.store_code
          data['UserType'] = userType
          data['From'] = item.time_data.from_time
          data['To'] = item.time_data.to_time
          data['Duration'] = item.time_data.duration
          data['SessionType'] = sess_type
          data['SessionDate'] = item.time_data.session_date
          data['Status'] = userStatus
          data['session_status'] = session_status

          if(item.user_attributes)
            {
              Object.entries(item.user_attributes).forEach(([key, value]) => {
                if(key == 'Region' && item.registered == 1){
                  data[key+"(User Attributes)"] = item.user_region
                }else{
                  data[key+"(User Attributes)"] = value
                }
              })
            }
          that.excelUser.push(data);

      });
      // this.session_users.forEach(function (item: any) {   
      // }) 
      this.excelService.exportAsExcelFile(this.excelUser, 'lc_session');   
    });
  }
  // add_score(user){
  //   console.log(user);

  //   const data = {
  //     "user": user,
  //     'sid': this.sid
  //   };
  //   // console.log(data)

  //   let getPromise = new Promise((resolve, reject) => {
  //     this.classroomService.user_score_update(data).toPromise().then(res => {
  //       //console.log(res)
  //       if (res == 'success') {
  //         alertify.success('Score added successfully');
  //         // this.fetchParticipants();
  //       } else {
  //         alertify.error('Sorry, Please check the ID');
  //       }
  //       //this.individualsList = res;

  //     })
  //   })


  // }
  // onInputChange(event:any, index:any){
  //   this.scoreSec = true;
  //   // console.log(event.target.value)
  // }
  // onInputPostScore(event:any, index:any){
  //   this.scoreSec = true;
  //   // console.log(event.target.value)
  // }

}