import { ChangeDetectionStrategy, Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule } from '@angular/forms';
import { InfogeonClassroomService } from '../infogeon-classroom.service'
import * as alertify from 'alertifyjs';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalConstants } from 'src/app/global-const';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as S3 from 'aws-sdk/clients/s3';
import { AWSkeySet, s3_file_path, link_base_Url, baseUrl } from 'src/app/config/api';
import { NgbDate, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IgxDatePickerComponent, IgxSnackbarComponent } from 'igniteui-angular';
import { DatePipe, registerLocaleData } from '@angular/common';
import { DateTime } from 'luxon';
import localeFr from '@angular/common/locales/fr';
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

var timezonesjson = require('timezones.json');

function padNumber(value: number | null) {
  if (!isNaN(value) && value !== null) {
    return `0${value}`.slice(-2);
  }
  return '';
}

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('/');

      let dateObj: NgbDateStruct = { day: <any>null, month: <any>null, year: <any>null }
      const dateLabels = Object.keys(dateObj);

      dateParts.forEach((datePart, idx) => {
        dateObj[dateLabels[idx]] = parseInt(datePart, 10) || <any>null;
      });
      return dateObj;
    }
    return null;
  }

  static formatDate(date: NgbDateStruct | NgbDate | null): string {
    if (date) {
      const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
      const monthAbbreviation = monthNames[date.month - 1] || '';
      return `${padNumber(date.day)}/${monthAbbreviation}/${date.year || ''}`;
    }
    return '';
  }

  format(date: NgbDateStruct | null): string {
    return NgbDateCustomParserFormatter.formatDate(date);
  }
}

@Component({
  selector: 'app-create-session',
  templateUrl: './create-session.component.html',
  styleUrls: ['./create-session.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateSessionComponent implements OnInit {

  modalRef: NgbModalRef = null;

  //  timeZoneUrlFormControl = new FormControl('', [Validators.required]);

  formGroup: FormGroup;
  accountSelect: any;
  selectedClassRoomType: any = '';
  //moduleList: any[] = [];
  //groupList: any[] = [];
  selectedModules: any[] = [];
  modDropdownList: any[] = [];
  groupDropdownList: any[] = [];
  individualsList: any[] = [];
  selectedGroups: any[] = [];
  selectedAttribute: any[] = [];
  selectedAttributeOption: any[] = [];
  selectedIndividuals: any[] = [];
  enrollEnable: boolean = true;
  modDropdownSettings: any;
  timeZoneList: any = [];
  selectedTimezone: any = [];
  display_timezone: any;
  current_timezone: any;
  is_auto_entrollment = 0;

  timezone = 'Asia/Kolkata';

  dropdownSettings = {};
  // from_time:any;
  // to_time:any;
  groupDropdownSettings = {};
  gIndDropdownSettings = {};
  sess_details: any;
  session_name = 'Team Session';
  session_type: any;
  classroom_type_set: any;
  mid: any;
  skill_id:any;
  subskill_id:any;
  from_time_set: any;
  to_time_set: any;
  meeting_link: any;

  user_list: any;
  assign_gid: any;
  assign_attribute: any;
  assign_attribute_option: any;
  assign_attribute_text: any;
  geolocation: any;
  address: any;
  sessionDate: Date = new Date();
  currentTime = new Date();
  nextHourTime = new Date()
  model: NgbDateStruct;
  minDate: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };
  // minTime: { hour: number; minute: number; } | null;
  currentYear: number;
  currentMonth: number;
  currentDay: number;
  selectedYear: number;
  selectedMonth: number;
  selectedDay: number;
  currentHour: number;
  currentMin: number;
  selectedHour: number;
  selectedMin: number;
  minTime: any;
  formatted_epochTime: any;
  min_epochTime: any;
  inst_session_show: any;
  schedule_session_show: any;

  selectedSkills: any[] = [];
  skillsDropdownList: any[] = [];
  skillsDropdownSettings: any;

  selectedSubSkills: any[] = [];
  subSkillsDropdownList: any[] = [];
  subSkillsDropdownSettings: any;

  allSkills:any[] = [];
  selectedTrainerType:any = [];
  trainerDropdownSettings:any;

  attributeDropdownSettings = {
    singleSelection: true,
    text: "Select Attribute",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    showCheckbox: true,
    labelKey: 'attribute_name',
    maxHeight: 120,
    // lazyLoading: true,
    primaryKey: 'aid',
    searchBy: ["aid", "attribute_name"],
    classes: "select_user_drop primaryText select_user_attri"
  };
  attributeOptionDropdownSettings = {
    singleSelection: true,
    text: "Select Attribute Option",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    showCheckbox: true,
    labelKey: 'attribute_option',
    maxHeight: 120,
    // lazyLoading: true,
    primaryKey: 'aoid',
    searchBy: ["aoid", "attribute_option"],
    classes: "select_user_drop primaryText select_user_attri select_user_attri_option"
  };

  trainer_type=[
    {'id': 1, 'itemName': 'Facilitator'}, 
    {'id': 2, 'itemName': 'Lead Trainer'}];
  trainer_type_id: any;

  constructor(private formBuilder: FormBuilder,
    private classroomService: InfogeonClassroomService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>,
    private titleService: Title,
   
  ) {
    this.titleService.setTitle("Create Classroom");
    registerLocaleData(localeFr);
  }

  ngOnInit(): void {

    // console.log('selectedModules->',this.sess_details)

    this.current_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    this.nextHourTime.setHours(this.nextHourTime.getHours() + 1);
    this.model = this.getCurrentDateTime();
    this.setCurrentTime()
    //console.log(this.minDate)

    //this.updateSecondDropdownOptions();
    // this.AllModule();
    this.AllGroups();
    this.getAttributes();
    this.AllSkills();
    this.AllrtmList();

    this.modDropdownSettings = {
      singleSelection: true,
      text: "Select a topic",
      //selectAllText:'Select All',
      //unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      showCheckbox: true,
      labelKey: 'itemName',
      maxHeight: 120,
      lazyLoading: true,
      // primaryKey:'id',
      searchBy: ["itemName"],
      classes: "modules_drop primaryText"
    };


    this.gIndDropdownSettings = {
      singleSelection: false,
      text: "Select Users",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      showCheckbox: true,
      labelKey: 'name_email_combine',
      maxHeight: 120,
      //lazyLoading: true,
      primaryKey: 'uid',
      searchBy: ["uid", "full_name", "first_name", "last_name","email_id"],
      classes: "modules_drop primaryText",
      checkedStyle: 'font-weight:bold', // Optional: Add a visual indication for selected items
      autoUnselect: false, // Optional: Prevent automatically unselecting items
      enableCheckAll: true, // Optional: Enable the "Select All" option
    };


    this.groupDropdownSettings = {
      singleSelection: true,
      text: "Select group",
      //selectAllText:'Select All',
      //unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      showCheckbox: true,
      labelKey: 'group_name',
      maxHeight: 120,
      lazyLoading: true,
      // primaryKey: 'acid',
      searchBy: ["id", "group_name"],
      classes: "modules_drop primaryText"
    };

    this.dropdownSettings = {
      singleSelection: true,
      text: 'Select timezone',
      enableSearchFilter: true,
      classes: "modules_drop primaryText",
      searchBy: ["id", "itemName"],
    };

    this.formGroup = this.formBuilder.group({
      selected: [],
      accountSelect: [],
      assign_gid: [],
      selectUsers: [],
      session_name: [this.session_name],
      session_date: [this.currentTime],
      to_time: [this.nextHourTime],
      from_time: [this.currentTime],
      classroom_type: ['0'],
      meeting_link: [],
      address: [],
      geolocation: [],
      //timezone: [],
      skillsSelect:[],
      subSkillSelect:[],
      assign_attribute:[],
      assign_attribute_option:[],
      assign_attribute_text:[],
      trainerType: [], 
    });
    this.selectedTimezone = [
      { 'id': 'Asia/Kolkata', 'itemName': 'Asia/Kolkata' }
    ]



    this.route.params.subscribe(paramsId => {
     // var that = this;

      if(paramsId.typeId == 0){
       this.inst_session_show = true
       this.schedule_session_show = false
       //this.formGroup.get('selected').value === 'instant';
       this.formGroup.get('selected').setValue('instant');
      }else{
       this.inst_session_show = false
       this.schedule_session_show = true
       this.formGroup.get('selected').setValue('schedule');
       //this.isSessionSelected;
       //this.isSessionSelected = true
      }


      //console.log(this.session_type,'url')

      });

      this.skillsDropdownSettings = {
        singleSelection: true,
        text: "Select a category",
        //selectAllText:'Select All',
        //unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        showCheckbox: true,
        labelKey: 'itemName',
        maxHeight: 120,
        lazyLoading: true,
        // primaryKey:'id',
        searchBy: ["itemName"],
        classes: "modules_drop primaryText"
      };

      this.subSkillsDropdownSettings = {
        singleSelection: true,
        text: "Select a sub category",
        //selectAllText:'Select All',
        //unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        showCheckbox: true,
        labelKey: 'itemName',
        maxHeight: 120,
        lazyLoading: true,
        // primaryKey:'id',
        searchBy: ["itemName"],
        classes: "modules_drop primaryText"
      };

      this.trainerDropdownSettings = {
        singleSelection: true,
        text: "Select a Trainer",
        //selectAllText:'Select All',
        //unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        showCheckbox: true,
        labelKey: 'itemName',
        maxHeight: 120,
        lazyLoading: true,
        // primaryKey:'id',
        searchBy: ["itemName"],
        classes: "modules_drop primaryText"
      };



  }

  createSession() {
    // console.log(this.formGroup.value)

    this.sess_details = this.formGroup.value
    this.session_name = this.sess_details['session_name']
    this.meeting_link = this.sess_details['meeting_link']
    this.user_list = this.sess_details['selectUsers']
    

    this.address = this.sess_details['address']
    this.geolocation = this.sess_details['geolocation']

    const sess_date = this.sess_details['session_date']
    const date_sess_set = new Date(sess_date.year, sess_date.month - 1, sess_date.day);
    // console.log(date_sess_set)
    // const date_sess_set = new Date(sess_date);
    const formattedSessDate = this.datePipe.transform(date_sess_set, 'yyyy-MM-dd');

    const day_f = this.datePipe.transform(date_sess_set, 'dd');
    const month_f = this.datePipe.transform(date_sess_set, 'M');
    const year_f = this.datePipe.transform(date_sess_set, 'yyyy');


    const monthNumber = parseInt(month_f, 10);
    const yearNumber = parseInt(year_f, 10);
    const dayNumber = parseInt(day_f, 10);


    this.from_time_set = this.sess_details['from_time']
    const from_date = new Date(this.from_time_set);

    const formattedFromTime = this.datePipe.transform(from_date, 'HH:mm');

    const fHour = this.datePipe.transform(from_date, 'HH');
    const formattedFromHour = parseInt(fHour, 10);


    const fMin = this.datePipe.transform(from_date, 'mm');
    const formattedFromMin = parseInt(fMin, 10);

    const fromDateString = formattedSessDate + ' ' + formattedFromTime + ' ' + this.timezone;
    const dateTime = DateTime.fromFormat(fromDateString, 'yyyy-MM-dd HH:mm z', { zone: this.timezone });
    const fromEpochTime = dateTime.toSeconds();


   // console.log(day_f,month_f,year_f,'formattedSessDate')

    const selectedDate = new Date(yearNumber, monthNumber - 1, dayNumber);


    this.to_time_set = this.sess_details['to_time']
    const to_date = new Date(this.to_time_set);
    const formattedToTime = this.datePipe.transform(to_date, 'HH:mm');

    const toDateString = formattedSessDate + ' ' + formattedToTime + ' ' + this.timezone;
    const toDateTime = DateTime.fromFormat(toDateString, 'yyyy-MM-dd HH:mm z', { zone: this.timezone });
    const toEpochTime = toDateTime.toSeconds();


    //const timeZoneOffset = new Date().getTimezoneOffset();




    //console.log(timeZoneOffset,'timeZoneOffset')
    //console.log(tzdatez,'tzdatez')


    if (this.sess_details['selected'] == 'instant') {
      this.session_type = 0;
      this.assign_gid = 0;
    } else {
      this.session_type = 1;

      // if (this.sess_details['assign_gid'].length != 0) {
      // this.assign_gid = this.sess_details['assign_gid'][0].id
      // } else {
      //   this.assign_gid = 0;
      // }
    }


    if (this.isSameDate(this.currentTime, selectedDate)) {
      const currentHour = this.currentTime.getHours();
      const currentMinute = this.currentTime.getMinutes();
      const min_date = new Date();

      const timezone = this.timezone;

      min_date.setHours(currentHour, currentMinute);
      const min_date_local = min_date.toLocaleString('en-US', { timeZone: timezone });
      const min_date_with_timezone = new Date(min_date_local);
      this.min_epochTime = Math.floor(min_date_with_timezone.getTime() / 1000);

      //console.log(this.min_epochTime)


      const format_date = new Date();
      format_date.setHours(formattedFromHour);
      format_date.setMinutes(formattedFromMin);
      this.formatted_epochTime = Math.floor(format_date.getTime() / 1000);

      if(this.formatted_epochTime < this.min_epochTime){
        alertify.error('Please select a upcoming time.');
      }else{
         this.validations_check(fromEpochTime,toEpochTime,sess_date)

      }
    }else{

     this.validations_check(fromEpochTime,toEpochTime,sess_date)




    }

  }

  validations_check(fromEpochTime,toEpochTime,sess_date){
    // console.log('validate function', this.sess_details)

    // console.log(this.sess_details['skillsSelect']?.length)
    // console.log(this.sess_details['classroom_type'])

    if (this.sess_details['accountSelect'].length == 0) {
      alertify.error('Select a module');
    } else if (this.session_name == '' || this.session_name == null) {
      alertify.error('Session name is required');
    } else if (sess_date == null || this.from_time_set == null || this.to_time_set == null) {
      alertify.error('Date and time is required');
    } else if (fromEpochTime > toEpochTime) {
      alertify.error('from time should be less than to time');
    } 
    // else if (this.session_type == 1 && this.sess_details['assign_gid'].length == 0) {
    //   alertify.error('Select a group');
    // } 
    else if (this.sess_details['skillsSelect'].length == 0) {
      alertify.error(' Select a category');
    }else if (this.subskill_id = this.sess_details['subSkillSelect'].length == 0) {
      alertify.error(' Select a sub category');
    }else if (this.sess_details['classroom_type'] == null){
      alertify.error('Select classroom type');
    }
    // else if (this.session_type == 1 && this.sess_details['selectUsers'].length == 0) {
    //   alertify.error('Select atleast one user');
    // }
    
    else {
      this.mid = this.sess_details['accountSelect'][0]['id']
      this.skill_id = this.sess_details['skillsSelect']?.length > 0 ? this.sess_details['skillsSelect'][0]['id'] : 0;
      this.subskill_id = this.subskill_id = this.sess_details['subSkillSelect']?.length > 0 ? this.sess_details['subSkillSelect'][0]['id'] : 0;
      this.trainer_type_id = this.sess_details['trainerType'].length > 0 ? this.sess_details['trainerType'][0]['id'] : 0;
      this.spinner.show();

      const save_data = {
        "mid": this.mid,
        "session_name": this.session_name,
        "session_date": fromEpochTime,
        "from_time": fromEpochTime,
        "to_time": toEpochTime,
        "timezone": this.timezone,
        "classroom_type": this.sess_details['classroom_type'],
        "session_type": this.session_type,
        "meeting_link": this.meeting_link ? this.meeting_link : null,
        "is_auto_entrollment": this.is_auto_entrollment ? this.is_auto_entrollment : 0,
        // "assign_gid": this.assign_gid,
        "users_list": this.user_list,
        "geolocation": this.geolocation ? this.geolocation : null,
        "address": this.address ? this.address : null,
        "skill_id": this.skill_id,
        "subskill_id": this.subskill_id,
        "is_lc_session" : 0, 
        'trainer_type' : this.trainer_type_id
      };

      // console.log('save data-->', save_data)
      let compPromise = new Promise((resolve, reject) => {
        this.classroomService.create_session(save_data).toPromise().then(res => {
          // console.log(res)
          this.spinner.hide();
          alertify.success('Success');
          // console.log(this.formGroup.get('selected').value)
          this.router.navigate(['/classroom/rtm_add_participant/'+res]);
          // this.router.navigate(['/classroom/view_session/'+res]);
          // if(this.formGroup.get('selected').value === 'instant')
          // {
            
          //   this.router.navigate(['/classroom/view_session/'+res]);
          // }
          // else{
          // this.router.navigate(['/classroom/session_list']);
          // }

        })
      });

    }
  }

  get isSessionSelected() {
    return this.formGroup.get('selected').value === 'schedule';
  }

  onClsTypeChange(event: any) {
    // console.log(event)
    this.selectedClassRoomType = event.value;
  }


  // AllModule() {
  //   this.classroomService.module_list().subscribe(moduleList => {
  //     this.modDropdownList = moduleList;
  //   })
  // }
  //add by geetha-get all rtm topic list
  AllrtmList() {
    this.classroomService.rtm_topic_list().subscribe(rtmTopicList => {
      this.modDropdownList = rtmTopicList;
    })
  }

  timezone_change(content: any) {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title'
    })
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.loadTimeZoneDD();
  }

  loadTimeZoneDD() {

    let collection: any[] = [];
    timezonesjson.forEach(function (item: any) {
      collection.push({ id: item.utc[0], itemName: item.utc[0] });
    });
    this.timeZoneList = collection;
    // console.log(collection);

  }


  AllGroups() {
    this.classroomService.group_list().subscribe(groupList => {
      this.groupDropdownList = groupList;
      //console.log(this.modDropdownList);
    })
  }
  attribute:any
  getAttributes() {
    
    this.classroomService.attribute_list().subscribe(attributeList => {
      this.attribute = attributeList;
      console.log(this.attribute);
    })
  }

  onSelectedValueChange(value: any) {
    // console.log(value); // You can perform any logic with the selected value here
    this.timezone = value.id
    //this.modalService.close;

  }


  closeModal() {
    // console.log(this.modalRef)
    if (this.modalRef !== null) {
      this.modalRef.dismiss();
      this.modalRef = null; // Reset the modal reference
    }
    // this.modalRef.close();
  }


  onFirstDropdownChange(value: any) {
    // Update the second dropdown options whenever the first dropdown selection changes
    this.updateSecondDropdownOptions(value);
  }


  updateSecondDropdownOptions(value) {
    // Reset the second dropdown options
    this.individualsList = [];

    const data = {
      "assign_gid": value.id
    };

    let getPromise = new Promise((resolve, reject) => {
      this.classroomService.group_users_list(data).toPromise().then(res => {

        this.individualsList = res;

      })
    })

  }


  enableEnroll() {
    this.enrollEnable = true;
    this.is_auto_entrollment = 0;
  }

  disableEnroll() {
    this.enrollEnable = false;
    this.is_auto_entrollment = 1;
  }

  getCurrentDateTime(): NgbDateStruct {
    const now = new Date();
    return {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate()
    };
  }

  onDateSelect() {
    this.selectedYear = this.model.year
    this.selectedMonth = this.model.month
    this.selectedDay = this.model.day

    const selectedDate = new Date(this.model.year, this.model.month - 1, this.model.day);

    if (this.isSameDate(this.currentTime, selectedDate)) {
      const currentHour = this.currentTime.getHours();
      const currentMinute = this.currentTime.getMinutes();

      this.minTime = this.formatTime(currentHour, currentMinute, 0)

      // this.minTime = { hour: currentHour, minute: currentMinute };
    } else {
      this.minTime = null;
    }
  }

  formatTime(hour: number, minute: number, second: number): string {
    const formattedHour = this.padZero(hour);
    const formattedMinute = this.padZero(minute);
    const formattedSecond = this.padZero(second);

    return `${formattedHour}:${formattedMinute}:${formattedSecond}`;
  }

  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }


  isSameDate(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  setCurrentTime() {
    this.currentYear = this.currentTime.getFullYear();
    this.currentMonth = this.currentTime.getMonth();
    this.currentDay = this.currentTime.getDate();
    this.currentHour = this.currentTime.getHours();
    this.currentMin = this.currentTime.getMinutes();

    this.minTime = this.formatTime(this.currentHour, this.currentMin, 0)
  }

  AllSkills() {
    this.classroomService.all_skills_list().subscribe(allSkills => {
      this.allSkills = allSkills;
      // Update the skills and subskills dropdown lists
      this.updateSkillsDropdownList();
      this.updateSubSkillsDropdownList();
    });
  }

  onModuleSelect(selectedValue: any): void {
    // Handle the selected value here
    // this.classroomService.get_selected_module_skills_list(selectedValue).subscribe(
    //   (response: any) => {
    //     // Assuming the response has a 'skills_list' property
    //     const skillsList = response.skills_list;
    //     // console.log('res skill list', skillsList)
    //     // Clear the existing selections and dropdown list
    //     this.selectedSkills = [];
    //     this.selectedSubSkills = [];
    //     this.skillsDropdownList = [];

    //     for (const item of skillsList) {
    //       if ('skill_id' in item) {
    //         // It's a skill
    //         this.selectedSkills.push({
    //           id: item.skill_id,
    //           itemName: item.skill_title,
    //           sub_id:item.sub_id
    //         });
    //       } else if ('subskill_id' in item) {
    //         // It's a subskill
    //         this.selectedSubSkills.push({
    //           id: item.subskill_id,
    //           itemName: item.subskill_title
    //         });
    //       }
    //     }
    //     // Update the skills dropdown list based on all skills
    //     this.updateSkillsDropdownList();
    //     this.updateSubSkillsDropdownList();

    //     // Force change detection to update the view immediately
    //     this.cdr.detectChanges();
    //   },
    //   (error: any) => {
    //     // Handle the error if needed
    //     console.error('Error fetching module skills:', error);
    //   }
    // );
  }

  
  updateSkillsDropdownList(): void {
    this.skillsDropdownList = this.allSkills
      .filter(skill => skill.entity_type === 'category')
      .map(skill => ({ id: skill.id, itemName: skill.title }));
  }

  updateSubSkillsDropdownList(): void {
    // Get the IDs of the selected skills
    const selectedSkillIds = this.selectedSkills.map(selected_skill => selected_skill.id);

    // Filter sub-skills based on the selected skills
    this.subSkillsDropdownList = this.allSkills
      .filter(skill => skill.entity_type === 'sub-category' && selectedSkillIds.includes(skill.sub_id))
      .map(skill => ({ id: skill.id, itemName: skill.title }));

  }
  

  onSkillSelect(selectedValue: any): void {
    if(selectedValue.length > 0){
      const reselectedSkillIds = selectedValue.map(reselected_skill => reselected_skill.id);
      
      // Filter sub-skills based on the selected skills
      this.subSkillsDropdownList = this.allSkills
        .filter(skill => skill.entity_type === 'sub-category' && reselectedSkillIds.includes(skill.sub_id))
        .map(skill => ({ id: skill.id, itemName: skill.title }));
  
    }
   
  }
  selectedAttributeText:any
 
  isAttributeMultiSelect=false
  isAttributeNoOption=false
  isAttribute =false
  createUserModel(content) {
    // this.selectedAttribute = []
    // this.selectedAttributeOption = []
    
    this.selectedAttributeText = ""
    this.selectedAttribute = []
    this.selectedAttributeOption = []
    this.isAttributeMultiSelect = false
    this.isAttributeNoOption = false
    this.isAttribute = true

    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title'
    });
    
  }
  isAttributeSelect=false;
  attributeOptions:any
  onSelectedChange(value){
    // console.log(this.selectedAttribute)
    // console.log(this.selectedAttribute[0].attribute_option.length)
    if(this.selectedAttribute.length>0)
    {
      this.isAttributeSelect = true
    }
    if(this.selectedAttribute[0].attribute_option.length >0)
    {
      this.isAttributeMultiSelect = true
      this.isAttributeNoOption = false
    }
    else
    {
      this.isAttributeMultiSelect = false
      this.isAttributeNoOption = true
    }
    this.attributeOptions = value.attribute_option
  }
  attributeName:any
  isselectedAttribute:false
  attributeText:any
  selectedUsers:any
  get_users(){
    // console.log(this.selectedAttribute)
    // console.log(this.selectedAttribute[0].attribute_option)
    this.attributeName =this.selectedAttribute[0].attribute_name
    if(this.selectedAttribute == undefined)
    {
      this.isselectedAttribute = false
      alertify.error("Please select attribute")
    }
    else if(this.selectedAttributeOption == undefined && this.isAttributeMultiSelect)
    {
      this.isselectedAttribute = false
      alertify.error("Please select attribute option")
      this.selectedAttributeText = ""
    }
    else if(this.selectedAttributeText == undefined || this.selectedAttributeText == "" && this.isAttributeNoOption)
    {
      this.isselectedAttribute = false
      alertify.error("Please enter the text")
    }
    else
    {
      // this.isselectedAttribute = true
      this.attributeText = (this.selectedAttributeText != "")?this.selectedAttributeText:this.selectedAttributeOption[0].attribute_option
      console.log(this.selectedAttributeText)

      var that = this;
      let getPromise = new Promise((resolve, reject) => {
        var data = 
        {
          "selectedAttributeOption":(this.selectedAttributeOption==undefined)?[]:this.selectedAttributeOption,
          "selectedAttribute":this.selectedAttribute,
          "attributeText":(this.selectedAttributeText != "")?this.selectedAttributeText:""
        }
        this.classroomService.get_users_by_attribte(data).toPromise().then(res => {
          res.forEach((item, idx) => {
              // console.log(item)
              // console.log(item.email_id)
              // console.log(item.full_name)
              item.name_email_combine = item.full_name+" - "+item.email_id
          });
          // console.log(res)

          this.individualsList = res
          this.selectedIndividuals = res
         this.formGroup.get('selectUsers').setValue(res)
          if(res.length>0)
          {
            
          }
          else
          {
            alertify.error("No users are present")
          }
          // this.add_new_users()
          if (that.modalRef !== null) {
            that.modalRef.dismiss();
            that.modalRef = null; // Reset the modal reference
          }
        

        })
      
      })
    }
  }
}
